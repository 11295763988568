import styled from 'styled-components';
import React, { ReactElement, useState } from 'react';
import Typography from '../atoms/typography';
import Spacer from '../atoms/spacer';
import Divider from '../atoms/divider';
import Card from '../atoms/card';
import Container from '../atoms/container';
import { Col, Row } from 'react-grid-system';
import Button from '../atoms/button';
import { formatPrice, getPriceWithPromo } from '../../services/prices';
import Checkbox from '../atoms/checkbox';
import Input from '../atoms/input';
import { PromoCodeRes } from '../../models/payment';

const CardWrapper = styled(Card)`
  padding: 1rem;
`;

const ItalicText = styled(Typography)`
  font-style: italic;
  font-size: 14px;
`;

const PriceText = styled(Typography)`
  font-weight: bold;
  text-align: right;
  white-space: nowrap;
  display: ;
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Total = styled(Typography)`
  font-weight: bold;
`;

const ColumnRight = styled(Col)`
  text-align: end;
`;

const RowCheckbox = styled(Row)`
  cursor: pointer;
`;

const StyledCheckbox = styled(Checkbox)`
  border: 1.5px solid
    ${({ theme, checked }) =>
      checked ? 'transparent' : theme.colors.placeholderPrimary};
  height: 16px;
  width: 16px;
`;

const InputPromoCode = styled(Input)`
  border: 1px solid ${({ theme }) => theme.colors.placeholderPrimary};
`;

const ButtonSubmitPromoCode = styled(Button)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.textPrimary};
`;

const PromoCodeValue = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: line-through;
`;

const PromoCodeTypo = styled(Typography)`
  font-size: 0.8rem;
`;
export interface IPriceRow {
  title: string;
  price: number;
  amount?: number;
  promo?: PromoCodeRes;
}

interface IOrderDetails {
  title?: string;
  orderDetails: IPriceRow[];
  disclaimer: string | ReactElement;
  onClick?: () => void;
  hasPromoCode?: boolean;
  handleSubmitPromoCode?: (promoCode: string) => void;
}

const OrderDetails: React.FC<IOrderDetails> = ({
  title = 'Kostnad',
  orderDetails,
  disclaimer,
  onClick,
  hasPromoCode = false,
  handleSubmitPromoCode
}) => {
  const [isPromoChecked, setIsPromoChecked] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState('');

  const getTotalPrice = () =>
    orderDetails.reduce(
      (tot, item) =>
        tot + getPriceWithPromo(item.price, item.promo) * (item.amount ?? 1),
      0
    );

  const getPrice = (row: IPriceRow) => {
    if (!row.promo) return formatPrice(row.price);

    return (
      <>
        <PromoCodeValue>{row.price}</PromoCodeValue>
        <Spacer orientation="horizontal" />
        {formatPrice(getPriceWithPromo(row.price, row.promo), true)}
      </>
    );
  };

  const onSubmitPromoCode = () => {
    if (!handleSubmitPromoCode) return;
    handleSubmitPromoCode(promoCodeValue);
    setPromoCodeValue('');
    setIsPromoChecked(false);
  };

  return (
    <>
      <Container>
        <CardWrapper>
          <Typography color="primary" variant="h3">
            {title}
          </Typography>
          {orderDetails.map((row) => (
            <div key={row.title}>
              <Spacer />
              <PriceRow>
                <Typography>{row.title}</Typography>
                <PriceText color="primary">{getPrice(row)}</PriceText>
              </PriceRow>
            </div>
          ))}
          <Spacer />
          <Divider color="primary" />
          <Spacer />
          <PriceRow>
            <Total>Total: </Total>
            <PriceText color="primary">
              {formatPrice(getTotalPrice(), true)}
            </PriceText>
          </PriceRow>
          {hasPromoCode && (
            <>
              <Spacer spacing={2} />
              <RowCheckbox
                gutterWidth={12}
                onClick={() => setIsPromoChecked((prevState) => !prevState)}
              >
                <Col xs="content">
                  <StyledCheckbox readOnly checked={isPromoChecked} />
                </Col>
                <Col xs="content">
                  <PromoCodeTypo>Jag har en kampanjkod</PromoCodeTypo>
                </Col>
              </RowCheckbox>
              {isPromoChecked && (
                <>
                  <Spacer />
                  <InputPromoCode
                    value={promoCodeValue}
                    onChange={(e) => setPromoCodeValue(e.target.value)}
                    placeholder="Ange kod"
                  />
                  <Spacer />
                  <ButtonSubmitPromoCode
                    primary
                    disabled={!promoCodeValue}
                    onClick={onSubmitPromoCode}
                  >
                    Aktivera kod
                  </ButtonSubmitPromoCode>
                </>
              )}
            </>
          )}
        </CardWrapper>
        <Spacer />
        <Row>
          <Col>
            <ItalicText>{disclaimer}</ItalicText>
          </Col>
          {onClick && (
            <ColumnRight>
              <Button primary onClick={onClick}>
                Boka fler
              </Button>
            </ColumnRight>
          )}
        </Row>
      </Container>
    </>
  );
};

export default OrderDetails;
