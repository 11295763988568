import styled, { css } from 'styled-components';
import Icon from '../components/atoms/icon';
import Typography from '../components/atoms/typography';
import { FULL_PAGE_HEIGHT } from '../services/sizeHelper';

interface IDisabled {
  disabled: boolean;
}

export const PageContainer = styled.div`
  min-height: ${FULL_PAGE_HEIGHT};
  display: flex;
  flex-direction: column;
`;

export const OrderPageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.pageBackground};
  flex: 1;
`;

export const ContainerSeamless = styled.div<{ show: boolean }>`
  ${({ show }) =>
    show &&
    `
padding: 1rem;
border-radius: 5px;
background-color: white;
`}
`;

export const ContainerLogin = styled.div`
  max-width: 375px;
  display: block;
  margin-right: auto;
`;

export const Header = styled(Typography)`
  font-size: 2.2rem;
`;

export const ModalDescription = styled(Typography)`
  font-weight: normal;
`;

export const HelperModalLink = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;
