import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import HeroBanner from '../components/organisms/heroBanner';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import Button from '../components/atoms/button';
import Container from '../components/atoms/container';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import useSnackbar from '../hooks/useSnackbar';
import { identifyWithBankID, updateUserData } from '../api';
import useFirebaseContext from '../hooks/useFirebaseContext';
import { SEAMLESS_VIEW_ID } from '../constants';
import { resetPaymentDetails } from '../utils/localStorage';
import * as Styled from '../styles/bestall';
import usePayment from '../hooks/usePayment';
import useAuth from '../hooks/useAuth';
import LoginCard from '../components/molecules/loginCard';
import TextInput from '../components/molecules/textInput';
import SEO from '../components/seo';
import Modal from '../components/molecules/modal';
import Routes from '../services/routes';
import { Link } from 'gatsby';
import usePaymentContext from '../hooks/usePaymentContext';
import PaymentStep1 from '../components/organisms/PaymentStep1';

const TestDataHint = () => (
  <p>
    För testkort.{' '}
    <a
      href="https://developer.swedbankpay.com/resources/test-data"
      target="__blank"
    >
      Klicka här
    </a>
  </p>
);

const Bestall: React.FC = () => {
  const { IS_MOBILE } = useScreenClassHelper();
  const { handleMessage } = useSnackbar();
  const { user, fetchData } = useFirebaseContext();
  const { isPaymentLoading } = usePayment();
  const [loading, setIsLoading] = useState(false);
  const { verifyBankIDSignIn } = useAuth();
  const [email, setEmail] = useState('');
  const [isUpdateUserLoading, setIsUpdateUserLoading] = useState(false);
  const [showHelperModal, setShowHelperModal] = useState(false);

  const { initialisePayment, showPaymentSection } = usePaymentContext();

  useEffect(() => {
    resetPaymentDetails();
  }, []);

  const handleLogin = async (ssn: string) => {
    try {
      setIsLoading(true);
      const res = await identifyWithBankID(ssn);
      if (!res) return false;
      const isSignInSuccessful = await verifyBankIDSignIn();
      setIsLoading(false);
      if (!isSignInSuccessful) {
        handleMessage(
          'Något gick fel, vänligen försök igen eller kontakta oss om problemet kvarstår'
        );
      }
    } catch (e) {
      setIsLoading(false);
      handleMessage(
        'Något gick fel, vänligen försök igen eller kontakta oss om problemet kvarstår'
      );
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleSaveEmailClick = async () => {
    if (!user?.ssn) return;
    setIsUpdateUserLoading(true);
    const successful = await updateUserData(user.ssn, { email });
    if (!successful) {
      handleMessage('Kunde inte spara e-postadress. Vänligen försök igen');
      setIsUpdateUserLoading(false);
      return;
    }
    await fetchData();
    setIsUpdateUserLoading(false);
  };

  const isEmailValid = () => {
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return regex.test(email);
  };

  const toggleShowHelperModal = () => {
    setShowHelperModal((prevState) => !prevState);
  };

  return (
    <>
      <SEO
        title="Beställ din panBox här och skydda dig vid nästa virusutbrott"
        description="Beställ din panBox här. Med vårt pandemikit minskar du risken att bli smittad vid ett virusutbrott."
      />
      <Styled.PageContainer>
        {showHelperModal && (
          <Modal
            primaryButtonProps={{
              title: 'Ok, tack!',
              onClick: toggleShowHelperModal
            }}
            description={modalDescription}
          />
        )}
        <HeroBanner
          leftPane={
            <>
              <Spacer spacing={6} />
              <Styled.Header variant="header" color="textSecondary">
                Beställ din panBox här
              </Styled.Header>
              <Spacer />
            </>
          }
        />
        <Styled.OrderPageWrapper>
          <Spacer spacing={3} />
          <Container>
            <Row justify="center">
              <Col md={8}>
                <Typography variant="h4">
                  Här kan du beställa ditt pandemikit i tre enkla steg.{' '}
                  <Styled.HelperModalLink onClick={toggleShowHelperModal}>
                    Läs mer här
                  </Styled.HelperModalLink>
                </Typography>
                <Spacer spacing={5} />
                <PaymentStep1 />
                <Spacer spacing={IS_MOBILE ? 4 : 8} />
                <Row direction="column">
                  <Typography variant="h3">
                    Steg 2. Logga in med BankID
                  </Typography>
                  <Spacer />
                  {!user?.ssn ? (
                    <Styled.ContainerLogin>
                      <LoginCard loading={loading} onLogin={handleLogin} />
                    </Styled.ContainerLogin>
                  ) : (
                    <>
                      <Typography>{`Inloggad som ${user.firstName} ${user.lastName}`}</Typography>
                      {!user?.email && (
                        <>
                          <Spacer spacing={2} />
                          <Row>
                            <Col xs={12} md={6}>
                              <TextInput
                                placeholder="Ange din e-postadress"
                                value={email}
                                onChange={handleEmailChange}
                                type="email"
                              />
                              <Spacer />
                              <Button
                                onClick={handleSaveEmailClick}
                                disabled={!isEmailValid()}
                                isLoading={isUpdateUserLoading}
                              >
                                Spara
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </Row>
                <Spacer spacing={IS_MOBILE ? 4 : 8} />
                <Row direction="column">
                  <Typography variant="h3">
                    Steg 3. Välj hur du vill betala
                  </Typography>
                  <Spacer spacing={2} />
                  {!showPaymentSection && user?.ssn && user?.email && (
                    <Button
                      primary
                      isLoading={isPaymentLoading}
                      onClick={initialisePayment}
                    >
                      Betala
                    </Button>
                  )}
                </Row>
                <Styled.ContainerSeamless show={showPaymentSection}>
                  {process.env.NODE_ENV !== 'production' &&
                    showPaymentSection && <TestDataHint />}
                  <div id={SEAMLESS_VIEW_ID} />
                </Styled.ContainerSeamless>
              </Col>
            </Row>
          </Container>
          <Spacer spacing={4} />
        </Styled.OrderPageWrapper>
      </Styled.PageContainer>
    </>
  );
};

const modalDescription = (
  <>
    <Styled.ModalDescription variant="h3">
      Här kan du beställa ditt pandemikit i tre enkla steg.
    </Styled.ModalDescription>
    <Spacer spacing={2} />
    <Styled.ModalDescription variant="body">
      1. Välj hur många boxar du vill beställa
    </Styled.ModalDescription>
    <Spacer spacing={0.5} />
    <Styled.ModalDescription variant="body">
      2. Logga in med bankID
    </Styled.ModalDescription>
    <Spacer spacing={0.5} />
    <Styled.ModalDescription variant="body">
      3. Välj hur du vill betala och fyll i din e-postadress. <b>Klart!</b>
    </Styled.ModalDescription>
    <Spacer spacing={2} />
    <Styled.ModalDescription variant="body">
      Vi använder oss av Swedbank pay och BankID för att säkerställa trygga
      betalningar online.
    </Styled.ModalDescription>
    <Spacer />
    <Styled.ModalDescription variant="body">
      Pandemikit från panBox innehåller produkter som hjälper dig att vara
      förberedd vid nästa virusutbrott. Vi uppdaterar innehållet vid behov och
      ser till att din panBox alltid innehåller det du behöver.
    </Styled.ModalDescription>
    <Spacer />
    <Styled.ModalDescription variant="body">
      Med panBox är du trygg nästa gång ett virus slår till oavsett om det är en
      ny pandemi eller säsongsinfluensa. Var förberedd och skydda dig själv och
      din familj vid nästa virusutbrott genom att beställa din panBox redan
      idag!
    </Styled.ModalDescription>
    <Spacer spacing={2} />
    <Styled.ModalDescription variant="body">
      Läs mer om innehållet i ditt{' '}
      <Link to={Routes.PRODUCTS}>pandemikit här</Link>.
    </Styled.ModalDescription>
  </>
);

export default Bestall;
