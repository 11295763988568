import React from 'react';
import { Col, Row } from 'react-grid-system';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import OrderDetails, { IPriceRow } from '../molecules/orderDetails';
import panbox from '../../images/panbox.png';
import plus from '../../images/icons/plus.png';
import minus from '../../images/icons/minus.png';
import usePaymentContext from '../../hooks/usePaymentContext';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../atoms/icon';
import useFirebaseContext from '../../hooks/useFirebaseContext';

interface IDisabled {
  disabled: boolean;
}

export const Image = styled.img`
  width: 100%;
  margin: auto;
`;

export const HeaderRow = styled.div`
  display: flex;
`;

export const CircularButton = styled(Icon)<IDisabled>`
  margin-left: 8px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
      filter: grayscale(50%);
    `}
`;

export const TypoAmount = styled(Typography)`
  width: auto;
`;

const PaymentStep1: React.FC = () => {
  const {
    panboxAmount,
    updatePanboxAmount,
    showPaymentSection,
    productCost,
    newSlots,
    subscriptionCost,
    handleAddPromoCode,
    promoCodeData
  } = usePaymentContext();

  const { user } = useFirebaseContext();

  const handleAmountChange = (isRemove: boolean) => {
    if (!isRemove || (isRemove && panboxAmount > 1)) {
      const newAmount = panboxAmount + (isRemove ? -1 : 1);
      updatePanboxAmount(newAmount);
    }
  };

  const panBoxesPerRow = useMemo(() => {
    if (panboxAmount > 4) {
      return 4;
    } else if (panboxAmount >= 2) {
      return 6;
    }
    return 12;
  }, [panboxAmount]);

  const getOrderDetails = () => {
    const arr: IPriceRow[] = [
      {
        title: 'Produktkostnad*',
        price: productCost,
        promo: promoCodeData
      }
    ];

    if (newSlots > 0) {
      arr.push({
        title: 'Månadskostnad**',
        price: subscriptionCost
      });
    }
    return arr;
  };

  return (
    <>
      <Typography variant="h3">
        Steg 1. Välj hur många boxar du vill beställa
      </Typography>
      <Spacer spacing={3} />
      <Row align="center">
        <Col md={6}>
          <Row>
            {Array.from(Array(panboxAmount > 9 ? 9 : panboxAmount)).map(
              (item, index) => (
                <Col key={index} xs={panBoxesPerRow}>
                  <Image alt="panBox order" src={panbox} />
                </Col>
              )
            )}
          </Row>
        </Col>
        <Col md={6}>
          <HeaderRow>
            <Spacer orientation="horizontal" />
            <TypoAmount variant="h2">{panboxAmount} st</TypoAmount>
            <Spacer orientation="horizontal" />
            <CircularButton
              onClick={() => handleAmountChange(false)}
              width={45}
              src={plus}
              disabled={!!user && showPaymentSection}
            />
            <CircularButton
              onClick={() => handleAmountChange(true)}
              width={45}
              src={minus}
              disabled={!!user && showPaymentSection}
            />
          </HeaderRow>
          <OrderDetails
            title="Kostnad"
            orderDetails={getOrderDetails()}
            handleSubmitPromoCode={handleAddPromoCode}
            hasPromoCode
            disclaimer={
              <>
                *Produktkostnaden utgår endast en gång.
                <br />
                **Månadsavgift för beredskapslager.
              </>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default PaymentStep1;
